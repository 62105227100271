h1 {
  @include font-size(35);
  font-weight: 300;
  margin: 40px 0;

  &.country {
    margin-bottom: 20px;
    margin-top: 0;

    @media (min-width: $screen-md-min) {
      margin-bottom: 0;
      margin-top: 40px;
    }
  }
}

h2 {
  @include font-size(40);
  font-weight: 300;
  margin: 30px 0;
}

h3 {
  @include font-size(20);
  font-weight: 700;
  margin: 20px 0;
}

hr {
  margin: 60px auto;
}

ul {

  &.inline {
    list-style: none;

    li {
      display: inline;
    }

    .divider {
      padding: 0 10px;
    }
  }
}

ul.arrow {
  list-style: none;

  li:before {
    content: '>';
    margin-right: 5px;
    @include font-size(16);
  }

  &-blue {
    li:before {
      color: $simplicitelFontColor;
    }
  }
}

ul.user-menu {
  margin-bottom: 0;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.blue {
  color: $simplicitelFontColor;
}

.text-left-sm {
  @media (min-width: $screen-sm-min) {
    text-align: left;
  }
}

.text-left-md {
  @media (min-width: $screen-md-min) {
    text-align: left;
  }
}

.text-left-lg {
  @media (min-width: $screen-lg-min) {
    text-align: left;
  }
}

.text-center-sm {
  @media (min-width: $screen-sm-min) {
    text-align: center;
  }
}

.text-center-md {
  @media (min-width: $screen-md-min) {
    text-align: center;
  }
}

.text-center-lg {
  @media (min-width: $screen-lg-min) {
    text-align: center;
  }
}

.text-right-sm {
  @media (min-width: $screen-sm-min) {
    text-align: right;
  }
}

.text-right-md {
  @media (min-width: $screen-md-min) {
    text-align: right;
  }
}

.text-right-lg {
  @media (min-width: $screen-lg-min) {
    text-align: right;
  }
}