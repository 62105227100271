.searchbox-container {
  height: 90px;
  line-height: 90px;
  background-color: rgba(0, 0, 0, .35);
  width: 100%;
  position: absolute;
  bottom: 0;

  input {
    display: none;

    @media (min-width: $screen-md-min) {
      display: inline-block;
      border: 0;
      box-shadow: none;
      border-radius: 2px 0 0 2px;
      padding: 0 20px 0 50px;
      background-image: url('../img/picto-loupe-noir.svg');
      background-position: 20px center;
      background-repeat: no-repeat;
      background-color: #ffffff;
      color: $defaultFontColor;
      height: 50px;

      @include input-placeholder {
        color: lighten($defaultFontColor, 20%);
      }
    }
  }

  select {
    display: inline-block;

    @media (min-width: $screen-md-min) {
      display: none;
    }
  }

  .searchbox-result-container {
    border: none;
    background-color: #ffffff;
    height: 0;
    position: relative;
    top: -10px;
    width: 100%;
    z-index: 200;
    border-radius: 2px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
    overflow-y: scroll;
    transition: height .5s;

    &.visible {
      border: 1px solid #e6e6e6;
      height: 150px;
    }
  }

  .searchbox-element-container {
    padding: 0 20px;
    height: 50px;
    line-height: 50px;
    cursor: pointer;

    &:hover, &.current {
      background-color: $navOriginBackgroundColor;
      color: #ffffff;
      transition: background-color .2s, color .2s;
    }
  }

  &-lite {
    bottom: auto;
    background-color: transparent;

    @media (min-width: $screen-md-min) {
      input {
        color: $defaultFontColor;
        border-bottom-color: $pricingBorder;
        background: url('../img/picto-loupe-noir.svg') center left no-repeat;

        @include input-placeholder {
          color: $searchBoxPlaceholderAltColor;
        }
      }
    }
  }
}