.breadcrumb {
  list-style: none;
  margin: 20px 0 0 0;
  padding: 0;
  @include font-size(12);

  a {
    color: inherit;
  }
  
  .active {
    color: $simplicitelFontColor;
  }

  li {
    display: inline-block;
  }

  li+li:before {
    padding: 0 5px;
    content: "/\00a0";
    color: $defaultFontColor;
  }
}