.contact {
  h3 {
    margin-top: 0;
  }

  p {
    margin-bottom: 4px;
  }

  input, textarea, .select-container {
    border-radius: 2px;
  }

  input, textarea {
    box-shadow: inset 0 1px 3px darken(#e6e6e6, .5%);
  }

  input {
    height: 42px;
  }

  textarea {
    resize: none;
  }

  .no-right-radius {
    border-radius: 2px 0 0 2px;
  }

  .no-left-radius {
    border-radius: 0 2px 2px 0;
  }

  .btn {
    padding-left: 50px;
    padding-right: 50px;
  }

  .select-container {
    height: 42px;
    line-height: 42px;
    margin: 0;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDEwMCAxMDAiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48cG9seWdvbiBmaWxsPSIjMDEwMTAxIiBwb2ludHM9IjIzLjEsMzQuMSA1MS41LDYxLjcgODAsMzQuMSA4MS41LDM1IDUxLjUsNjQuMSAyMS41LDM1IDIzLjEsMzQuMSAiLz48L3N2Zz4=) 98% 2px no-repeat;

    .select-results-container {
      top: -42px;

      &.visible {
        height: 126px;
      }
    }

    .select-option-container {
      height: 42px;
    }
  }
}