#top-destination {
  display: none;
  background-color: rgba(0, 180, 255, .9);
  position: absolute;
  z-index: 99;
  right: 30px;
  top: 30px;
  list-style-type: disc;
  list-style-position: inside;

  &.open {
    display: block;
  }

  li {
    color: #000;
    display: block;
  }
}