.overlay-wrapper { display: none; }
.overlay-content { display: none; }
.overlay-circle { display: none; cursor: pointer; }


.overlay-wrapper {
  z-index: 1000;
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  background-color: white;
  opacity: .8;

  &.open {
    display: block;
    animation: fadeIn .4s;
  }
}

.login {
  display: none;

  &.open {
    display: block;
    animation: fadeIn .4s;
  }

  &.overlay-content {
    z-index: 1001;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    padding: 60px 0 0 0;
    background: rgba(0, 180, 255, 0.8);

    @media (min-width: $screen-sm-min) {
      top: 50%;
      left: 50%;
      margin-top: -303px;
      margin-left: -303px;
      width: 606px;
      height: 606px;
      border-radius: 50%;
      padding: 150px 103px 103px 103px;
    }
  }

  &.overlay-circle {
    z-index: 1002;
    position: fixed;
    top: 0;
    left: 0%;
    margin: 0;
    padding: 0;
    line-height: 50px;
    width: 50px;
    height: 50px;
    background: rgba(0, 180, 255, 1);
    cursor: pointer;
    text-align: center;


    @media (min-width: $screen-sm-min) {
      top: 50%;
      left: 50%;
      padding: 35px;
      width: 150px;
      height: 150px;
      border-radius: 50%;
      line-height: 80px;
      margin-left: -303px;
      margin-top: -303px;
    }

  }

  hr {
    margin: 30px 0;
  }

  .clickable {
    padding-top: 10px;
    padding-bottom: 10px;

    cursor: pointer;

    &:hover {
      background-color: #009ee0;
    }
  }

  strong {
    color: #fff;
    @include font-size(25);
  }

  small {
    @include font-size(20);
  }

  .service-helper, .service-target {
    display: none;
  }
  
  .service {
    padding-top: 50px;
  }

  input, button {
    height: 50px;
    line-height: 50px;
    padding-top: 0;
    padding-bottom: 0;
  }

  form {
    margin-top: 10px;
  }

  a {
    color: darken(#009ee0, 5%);
  }

  p {
    color: #fff;

    &.big {
      @include font-size(30);
      color: $buttonSecondaryBackgroundColor;
    }

    small {
      @include font-size(12);
      color: #fff;
    }

    &.small {
      margin-top: -15px;
    }
  }

  .help-block {
    @include font-size(12);
    margin: 0;
  }
}
