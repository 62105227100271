.sprite-operateur {
  background: url('../img/sprite-operateurs.png') top left no-repeat transparent;
  width: 36px;
  height: 28px;
  display: inline-block;

  &-france-telecom {
    background-position: 0 0;
  }

  &-livebox {
    background-position: 0 -28.75px;
  }

  &-free {
    background-position: 0 -57.5px;
  }

  &-only {
    background-position: 0 -86.25px;
  }

  &-izi {
    background-position: 0 -115px;
  }

  &-skype {
    background-position: 0 -143.75px;
  }

  &-sfr {
    background-position: 0 -172.5px;
  }

  &-numericable {
    background-position: 0 -201.25px;
  }
}