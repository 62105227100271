// pair
.even {
  background-color: transparent;
}

// impair
.odd {
  border-radius: 2px;
  background-color: $oddBackgroundColor;
  border: 1px solid $oddBorderColor;
}

.table-row {
  height: 50px;
  line-height: 50px;
  display: inherit;
  color: inherit;
  margin-left: 0;
  margin-right: 0;

  img {
    margin-right: 10px;
  }
}