.legal {
  padding-top: 20px;
  background: transparent;
  @include font-size(11);
  margin-bottom: 30px;

  ul {
    list-style: none;
  }
}

.legal-mentions {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  @include font-size(11);
}

.countries-legal {
  padding: 20px 0;
  @include font-size(11);
  font-weight: 400;
  background-color: $tileBackgroundColor;
  border: 1px solid $pricingBorder;

  h2 {
    @include font-size(14);
    margin: 0 0 20px 0;
    font-weight: 400;
  }
  
  .col-md-2 {
    @media (min-width: $screen-md-min) {
      padding: 0;
    }
  }
}