.well {
  background-color: #d5333a;
}

.debug-card {
  border: 1px solid #e6e6e6;
  margin: 0 -10px;
  padding: 15px;

  strong {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 150px;
    display: inline-block;
  }
}