.comment {
  margin-top: 10px;
  margin-bottom: 20px;

  .reply {
    padding: 20px;
    background-color: #eee;
  }
}

.star {

  &.active {
    color: rgba(255, 238, 53, 0.82);
  }
}

.star-group {
  direction: rtl;
  display: inline-block;
  .star {
    cursor: pointer;
  }
}

.star-group > .star:hover,
.star-group > .star:hover ~ .star,
.star-group > .star.active,
.star-group > .star.active ~ .star {
  color: rgba(255, 238, 53, 0.82);
}

form .success {
  display: none;
}