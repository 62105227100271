.admin-area {
  margin-top: 50px;
  
  .menu {
    border: 1px solid $adminBorderColor;
    margin-bottom: 5px;
    background-color: #585858;

    @media (min-width: $screen-sm-min) {
      margin-bottom: 0;
    }

    ul {
      list-style: none;
    }

    li {
      height: 50px;
      color: #fff;
      border-bottom: 1px solid #585858;
      background-color: #7e7e7e;
      background-image: linear-gradient(bottom, rgba(0, 0, 0, .1), rgba(255, 255, 255, .1));

      &.logout {
        background-color: #fd5a5a;
        border-bottom: none;
      }

      &.nostyle {
        background-color: transparent;
        background-image: none;
      }

      &.active {
        background-color: #00b4ff;
      }
    }
    
    a {
      color: #fff;
      display: inline-block;
      height: 50px;
      width: 100%;
      padding-left: 30px;
      line-height: 50px;
      text-decoration: none;
    }

  }

  .content {
    border: 1px solid $adminBorderColor;

    @media (min-width: $screen-sm-min) {
      margin-left: 5px;
      margin-right: -5px;
    }
  }
  
  .header {
    background-color: $adminHeaderBackgroundColor;
    color: #fff;
    height: 50px;
    line-height: 50px;

    &-alt {
      background-color: $adminHeaderAltBackgroundColor;
    }

    &-border-top {
      border-top: 1px solid $adminBorderColor;
    }

    &-border-bottom {
      border-bottom: 1px solid $adminBorderColor;
    }
  }

  .fill {
    background-color: $adminBackgroundColor;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .clickable {
    cursor: pointer;
  }

  .table-head {
    background-color: $adminHeaderBackgroundColor;
    color: #fff;
    border-top: 1px solid $adminBorderColor;
    border-bottom: 1px solid $adminBorderColor;
  }

  .table-row {
    margin-left: -15px;
    margin-right: -15px;
    height: 50px;
    line-height: 50px;
    padding: 0;
    border-bottom: 1px solid $adminBorderColor;

    &:last-child {
      border-bottom: none;
    }

    .col-xs-3 {
      border-right: 1px solid #fff;

      &:last-child {
        border-right: none;
      }
    }
  }
  
}