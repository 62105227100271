.pricing {
  .switch {
    cursor: pointer;
    height: 60px;
    margin-bottom: 10px;
    line-height: 60px;
    border-radius: 2px;
    width: 100%;
    background-color: $tileBackgroundColor;
    border: 1px solid $oddBorderColor;

    @media (min-width: $screen-md-min) {
      width: 92%;
      margin-left: 15px;
    }

    &.active {
      border: 1px solid $pricingBorder;
      background-color: transparent;
      margin: 0 auto 10px;
      width: 100%;
      font-weight: 700;

      @media (min-width: $screen-md-min) {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        width: 308px;
        margin: 0 auto 10px 15px;
      }

      @media (min-width: $screen-lg-min) {
        width: 374px;
      }
    }

    img {
      margin-right: 10px;
    }
  }

  .block {
    padding: 30px 45px 0;
    @include font-size(15);
    min-height: 400px;

    @media (min-width: $screen-md-min) {
      min-height: 430px;
    }

    @media (min-width: $screen-lg-min) {
      padding: 30px 65px 0;
    }

    strong {
      @include font-size(40);
      font-weight: 700;
    }
    small {
      @include font-size(11);
      font-weight: 300;
    }

    ol {
      -webkit-margin-before: 0;
      -moz-padding-start: 0;
      -webkit-padding-start: 0;
      list-style: none;
      text-align: left;
      margin-top: 15px;
      
      li {
        margin-bottom: 10px;
      }
    }
  }

  .simplicitel {
    border-radius: 2px 2px 0 0;
    border: 1px solid $pricingBorder;

    @media (min-width: $screen-md-min) {
      border-radius: 0 0 0 2px;
      border-right: none;

      &:before {
        content: '';
        background-color: white;
        height: 58px;
        width: 1px;
        position: absolute;
        top: 0;
        left: -1px;
      }

      &.fixe:before {
        content: '';
        background-color: white;
        height: 58px;
        width: 1px;
        position: absolute;
        top: 70px;
        left: -1px;
      }
    }

    small, strong {
      color: $navOriginBackgroundColor;
    }

    em {
      font-style: normal;
      color: $navOriginBackgroundColor;
      font-weight: 700;
      @include font-size(20);
    }
  }

  .simplicitel-eco {
    border-radius: 0 0 2px 2px;
    background-color: $navOriginBackgroundColor;
    color: white;

    @media (min-width: $screen-md-min) {
      border-radius: 0 2px 2px 0;
    }
  }

  .mobile {
    display: block;
  }

  .fixe {
    display: none;
  }

  .other {
    display: none;

    border-radius: 2px 2px 0 0;
    border: 1px solid $pricingBorder;

    @media (min-width: $screen-md-min) {
      &:before {
        content: '';
        background-color: white;
        height: 58px;
        width: 1px;
        position: absolute;
        top: 140px;
        left: -1px;
      }
    }

    small, strong {
      color: $navOriginBackgroundColor;
    }

    em {
      font-style: normal;
      color: $navOriginBackgroundColor;
      font-weight: 700;
    }
  }

  ul {
    list-style: none;

    li {
      color: $pricingULColor;
      margin: 20px 0;
    }

    img {
      vertical-align: middle;
      margin-top: -5px;
    }
  }

  .legal {
    background-color: $tileBackgroundColor;
    border: 1px solid $pricingBorder;
    padding: 20px 10px;
    font-weight: 400;

    &:before, &:after {
      position: absolute;
      content: " ";
      border: solid transparent;
      height: 0;
      width: 0;
      bottom: 100%;
    }

    &:before {
      border-color: transparent;
      border-bottom-color: $pricingBorder;
      border-width: 11px;
      left: 25%;
      margin-left: -21px;
    }

    &:after {
      border-color: transparent;
      border-bottom-color: $tileBackgroundColor;
      border-width: 10px;
      left: 25%;
      margin-left: -20px;
    }
  }
}

.comparatif {
  .switch {
    cursor: pointer;
    height: 60px;
    margin-bottom: 10px;
    line-height: 60px;
    border-radius: 2px;
    width: 100%;
    background-color: $tileBackgroundColor;
    border: 1px solid $oddBorderColor;
    color: $defaultFontColor;
    display: block;

    @media (min-width: $screen-md-min) {
      width: 92%;
      margin-left: 15px;
    }

    &:active, &:hover {
      text-decoration: none;
      color: $defaultFontColor;
    }
  }

  .block {
    padding: 30px 20px;
    @include font-size(15);

    strong {
      @include font-size(40);
      font-weight: 700;
    }
    small {
      @include font-size(11);
      font-weight: 300;
    }

    ol {
      -webkit-margin-before: 0;
      -moz-padding-start: 0;
      -webkit-padding-start: 0;
      list-style: none;
      text-align: left;
    }
  }

  .left, .right {
    border: 1px solid $pricingBorder;
    border-radius: 2px;
  }

  .left {
    border-bottom: none;
    border-radius: 2px 2px 0 0;

    @media (min-width: $screen-sm-min) {
      border-right: none;
      border-bottom: 1px solid $pricingBorder;
      border-radius: 2px 0 0 2px;
    }
  }

  .right {
    border-top: none;
    border-radius: 0 0 2px 2px;

    @media (min-width: $screen-sm-min) {
      border-left: none;
      border-top: 1px solid $pricingBorder;
      border-radius: 0 2px 2px 0;
    }
  }

  .table-row {
    background-color: #f9f9f9;
    height: 46px;
    position: relative;
    padding-top: 13px;

    &:before, &:after {
      background-color: #fff;
      height: 12px;
      width: 101%;
      position: absolute;
    }

    &:before {
      top: 0;
      left: 0;
      right: 0;
    }

    &:after {
      bottom: 0;
      left: 0;
      right: 0;
    }

    .col-xs-5, .col-xs-7 {
      z-index: 2;
      margin-top: -4px;
    }

    .col-xs-7 {
      line-height: 16px;
    }
    
    img {
      margin-top: -26px;
    }

    p {
      margin: 0;
      padding: 0;
      font-weight: 700;
      @include font-size(20);


      &.small {
        @include font-size(10);
        font-weight: 300;
      }
    }

  }

  ul {
    list-style: none;

    li {
      color: $pricingULColor;
      margin: 20px 0;
    }

    img {
      vertical-align: baseline;
    }
  }
}