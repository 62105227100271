.footer {
  height: 110px;
  //line-height: 110px;
  padding-top: 40px;
  padding-bottom: 40px;

  ul {
    list-style: none;
    margin: 10px 0;
  }

  li {
    @media (min-width: $screen-sm-min) {
      display: inline-block;
      padding: 0 10px;
    }
  }

  .tile {
    padding: 15px;
  }
}