.tile {
  border: 1px solid #e6e6e6;
  margin: 0 -10px 10px;
  padding: 15px;
  background-color: $tileBackgroundColor;

  strong {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 150px;
    display: inline-block;

    &.big {
      @include font-size(40);
    }
  }
}

.tile-container {
  margin-bottom: 40px;
}