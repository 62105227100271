.svg {
  border: 0;
  vertical-align: middle;

  .cls-1 {
    fill: #666;
  }

  &.simplicitel-eco {
    .cls-1 { fill: #fff; }

    &-blue {
      .cls-1 { fill: $simplicitelFontColor; }
    }
  }
}

.svg-picto-1 {
  margin-left: 13px;
}

.svg-picto-2 {
  margin-left: 13px;
}

.svg-picto-3 {
  margin-left: 13px;
}

.svg-simplicitel-eco {
    .cls-1 {
      fill: #26b4f0;
    }
}