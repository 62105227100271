.alert {
  border: 1px solid #e6e6e6;
  background-color: #f8f8f8;
  border-radius: 2px;
  padding: 10px 20px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;

  &-notif {
    padding: 0 20px 10px;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      border-width: 0 0 20px 20px;
      border-style: solid;
      border-color: #f8f8f8 #f26d7d;
    }
  }



  .close {
    position: relative;
    top: 10px;
    right: -99%;
    padding: 0;
    border: none;
    background: none;
  }
}
