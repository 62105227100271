.hero {
  height: 365px;
  background-color: $navOriginBackgroundColor;
  background-size: cover;
  position: relative;
  padding-top: 72px;

  &.hero-small {
    height: 275px;
  }

  &.hero-big {
    height: 495px;
    padding-top: 56px;
  }

  &.hero-girl {
    background-image: url('../img/hero-girl.jpg');
    background-position: bottom center;
  }

  &.hero-mountain {
    background-image: url('../img/hero-mountain.jpg');
    background-position: bottom center;
  }

  &.hero-desk {
    background-image: url('../img/hero-desk.jpg');
    background-position: bottom center;
  }

  h1 {
    color: #ffffff;
    margin-top: 0;

    @media (min-width: $screen-md-min) {
      @include font-size($h1FontSize);
      line-height: 70px;
    }

    strong {
      font-weight: 700;
    }
  }

  ul {
    padding: 0;
    @include font-size(20);
    font-weight: 300;

    li {
      color: #ffffff;
      list-style: none;
      margin: 10px 0;
      
      img {
        margin-right: 5px;
      }
    }
  }
}
