.btn {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: none;
  @include font-size(17);
  color: #000000;
  padding: 6px 18px;
  touch-action: manipulation;
  border-radius: 2px;
  user-select: none;
  height: 40px;
  line-height: 1.7;

  &.btn-small {
    height: 36px;
  }

  &.btn-big {
    height: 50px;
  }

  &.btn-primary {
    background-color: $buttonPrimaryBackgroundColor;
    color: #ffffff;

    &:hover {
      background-color: darken($buttonPrimaryBackgroundColor, 5%);
      text-decoration: none;
    }
  }

  &.btn-secondary {
    background-color: $buttonSecondaryBackgroundColor;
    color: #ffffff;

    &:hover {
      background-color: darken($buttonSecondaryBackgroundColor, 5%);
      text-decoration: none;
    }
  }
  
  &.btn-store {
    background-color: #000000;
    color: #ffffff;
    height: 59px;
    width: 189px;
    margin-bottom: 10px;

    @media (min-width: $screen-sm-min) {
      margin-bottom: 0;
    }
  }
  
  &.btn-apple {
    background-image: url('../img/apple.png');
  }
  
  &.btn-google {
    background-image: url("../img/google.png");
  }

  &.btn-searchbox {
    height: 50px;
    border-radius: 0 2px 2px 0;
    margin-top: -3px;
  }
}