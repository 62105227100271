.nav {
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: #fff;
  width: 100%;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  font-weight: 400;

  .container {
    padding-left: 0;
    padding-right: 0;
  }

  .nav-menu {
    max-height: 0;
    overflow: hidden;
    transition: max-height ease .2s, padding ease .2s;
    padding-top: 1px;

    &.open {
      padding: 15px;
      max-height: 999px;
      transition: max-height ease .4s, padding ease .4s;
    }
  }

  .nav-header {
    height: 50px;
    line-height: 47px;
  }

  .burger {
    cursor: pointer;
  }

  ul {
    list-style: none;
  }

  li {
    display: block;
    text-align: left;
    height: 50px;
    line-height: 50px;
    padding: 0 10px;
    width: 100%;
  }

  a {
    text-transform: uppercase;
    color: $defaultFontColor;

    &.active, &:hover {
      color: $simplicitelFontColor;
    }
  }

  a.btn:hover {
    color: white;
    text-decoration: none;
  }

  .brand {
    color: $simplicitelFontColor;
    padding-left: 10px;
    text-decoration: none;
    @include font-size(20);
    font-weight: 300;
    
    img {
      margin-right: 5px;
    }
  }
}

@media (min-width: $screen-sm-min) {
  .nav {
    position: relative;
    @include font-size(15);
    color: $defaultFontColor;
    height: 75px;
    line-height: 75px;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.1);
    width: auto;
    border-right: none;
    left: auto;
    z-index: auto;

    .container {
      margin-right: auto;
      margin-left: auto;
      padding-right: 15px;
      padding-left: 15px;
    }

    .nav-header {
      height: inherit;
      line-height: inherit;
    }

    .nav-menu {
      max-height: 999px;
      padding: 0 15px;

      &.open {
        padding: inherit;
      }
    }

    ul {
      list-style: none;
    }

    li {
      display: inline-block;
      height: auto;
      width: auto;
      border-bottom: none;
      line-height: inherit;
      padding: 0;
    }

    a {
      margin: 0 8px;
      text-transform: none;
    }

    &-origin {
      background-color: $navOriginBackgroundColor;
      color: $navOriginFontColor;
      height: 30px;
      line-height: 30px;
      box-shadow: none;
      font-weight: 300;

      @include font-size($navOriginFontSize);

      a {
        color: $navOriginLinkFontColor;
        margin: 0 8px;

        &:hover, &.active {
          color: $navOriginLinkActiveFontColor;
          text-decoration: none;
        }
      }
    }

    .brand {
      padding-left: 0;
    }
  }
}

/** DUE TO POSITION FIXED */
body {
  margin-top: 51px;

  @media (min-width: $screen-sm-min) {
    margin-top: inherit;
  }
}