.faq {
  dt {
    font-weight: 300;
    color: $simplicitelFontColor;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  dd {
    display: none;
    margin: 10px 0 20px;
  }
}