.margin-top-50 {
  margin-top: 50px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

@media (min-width: $screen-md-min) {
  .col-md-no-padding-left {
    padding-left: 0;
  }

  .col-md-no-padding-right {
    padding-right: 0;
  }
}