html, body {
  font-family: $defaultFontFamily;
  font-weight: $defaultFontWeight;
  color: $defaultFontColor;
  font-size: 15px;
}

html {
  font-size: 62.5%;
}

img {
  border: 0;
  max-width: 100%;

  &.responsive {
    height: auto;
  }
}

textarea, input { outline: none; }

.form-control:focus { box-shadow: none; }

body {
  position: relative;
}

ul, ol, dl {
  padding: 0;
}