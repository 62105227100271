form {
  .col-sm-6 {
    &.left {
      @media (min-width: $screen-sm-min) {
        padding-right: 0;
      }
    }
    &.right {
      @media (min-width: $screen-sm-min) {
        padding-left: 0;
      }
    }
  }
  
  textarea.form-control {
    height: 200px;
  }

  .no-right-border {
    @media (min-width: $screen-sm-min) {
      border-right: none;
    }

    &:focus {
      border-right: 1px solid $input-border-focus;
    }

    // TODO Javascript
    &:focus ~ input {
      border-left: none;
    }
  }

  .select-container {
    border: 1px solid $input-border;
    padding: 0;
    height: 34px;
    line-height: 34px;
    cursor: pointer;
    margin: 5px 0;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDEwMCAxMDAiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48cG9seWdvbiBmaWxsPSIjMDEwMTAxIiBwb2ludHM9IjIzLjEsMzQuMSA1MS41LDYxLjcgODAsMzQuMSA4MS41LDM1IDUxLjUsNjQuMSAyMS41LDM1IDIzLjEsMzQuMSAiLz48L3N2Zz4=) center right no-repeat;

    span {
      padding: 0 12px;
      color: $navOriginBackgroundColor;
    }

    .select-results-container {
      border: none;
      background-color: white;
      height: 0;
      position: relative;
      top: -34px;
      width: 100%;
      z-index: 200;
      overflow: hidden;
      transition: height .2s;

      &.visible {
        border: 1px solid $input-border;
        height: 104px;
      }
    }

    .select-option-container {
      height: 34px;
      padding: 0 12px;

      &:hover, &.selected {
        color: #ffffff;
        background-color: $navOriginBackgroundColor;
      }
    }
  }
}