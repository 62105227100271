// Colors
//$defaultFontColor: #717171;
$defaultFontColor: #4f4f4f;
$simplicitelFontColor: #00aeef;

$navOriginBackgroundColor: #00aeef;
$navOriginFontColor: #bdecfd;
$navOriginLinkFontColor: #bdecfd;
$navOriginLinkActiveFontColor: #fffefe;

$buttonPrimaryBackgroundColor: #00aeef;
$buttonSecondaryBackgroundColor: #0078a5;

$adminBackgroundColor: #dff1f9;
$adminHeaderBackgroundColor: #00aeef;
$adminHeaderAltBackgroundColor: #008cc7;
$adminBorderColor: #717171;

$tileBackgroundColor: #f8f8f8;

$evenBackgroundColor: #ffffff;
$oddBackgroundColor: #f1f1f1;
$oddBorderColor: #e6e6e6;

//$pricingBorder: #b8b8b8;
$pricingBorder: #e6e6e6;
$pricingULColor: #62c462;

$searchBoxPlaceholderColor: #86c2d9;
$searchBoxPlaceholderAltColor: #b7b7b7;

// Sizes
$defaultFontSize: 15;

$h1FontSize: 50;

$navOriginFontSize: 11;

// Weight
$defaultFontWeight: 300;

// Font-family
@import url(http://fonts.googleapis.com/css?family=Open+Sans:300,700italic,400,300italic,700,400italic);
$defaultFontFamily: "Open Sans", "Arial", sans-serif;